
import { onMounted, computed, defineComponent, ref, toRef } from 'vue';
import BusinessClientDetails from '@/components/BusinessClients/BusinessClientDetails.vue';
import BusinessClientFooter from '@/components/BusinessClients/BusinessClientFooter.vue';
import BusinessClientHeader from '@/components/BusinessClients/BusinessClientHeader.vue';
import MobileFooterActions from '@/components/MobileFooterActions.vue';
import swal from 'sweetalert2';
import { BusinessClient, ClientContactError } from '@/models/BusinessClients';
import { api } from '@/services/Api';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import Q from 'q';

export default defineComponent({
    name: 'BusinessClientEdit',
    components: {
        BusinessClientDetails,
        BusinessClientFooter,
        BusinessClientHeader,
        MobileFooterActions
    },
    props: {
        id: {
            type: Number,
            default: 0
        },
        page: {
            type: Number,
            default: 1
        }
    },
    async setup(props) {
        const activeBtn = toRef(props, 'page');
        const loaded = ref(false);
        const contactsHasDuplicates = ref(false);
        const { t } = useI18n();
        const clientContactError = ref<ClientContactError[] | null>(null);
        const errorsClientDetails = ref<string[]>([]);
        const businessClient = ref<BusinessClient>(new BusinessClient());
        const showFooterActions = computed(() => activeBtn.value == 1 || activeBtn.value == 2);
        const amountOrder = ref(0);
        const totalInvoices = ref(0);
        const totalAllReceipts = ref(0);
        const totalBindedReceipts = ref(0);
        const totaInvoiceslLeftToBind = ref(0);
        const balance = ref(0);
        const currencySign = ref('');
        async function onMountedAction() {
            if (props.id) {
                const response = await api.getBusinessClientDetails(props.id);
                if (response.errorMessage) {
                    swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.errorMessage });
                    return;
                }
                if (response.data) {
                    businessClient.value = response.data.businessClient || new BusinessClient();
                    amountOrder.value = response.data.amountOrder;
                    totalInvoices.value = response.data.totalInvoices;
                    totalAllReceipts.value = response.data.totalAllReceipts;
                    totalBindedReceipts.value = response.data.totalBindedReceipts;
                    totaInvoiceslLeftToBind.value = response.data.totaInvoiceslLeftToBind;
                    balance.value = response.data.balance;
                    currencySign.value = response.data.currencySign;
                    loaded.value = true;
                }
            }
        }
        onMounted(async () => {
            await onMountedAction();
        });
        function back() {
            router.go(-1);
        }
        async function updateBusinessClient() {
            if (!businessClient.value.vatNumber) {
                const resultSwal = await swal.fire({
                    title: t('busines-client.warning.vat-number-doesnt-fill'),
                    icon: 'warning',
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4',
                        cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showCancelButton: true,
                    cancelButtonText: t('button.back'),
                    confirmButtonText: t('button.continue')
                });
                if (!resultSwal.isConfirmed) return;
            }
            swal.showLoading();
            clientContactError.value = null;
            errorsClientDetails.value = [];
            contactsHasDuplicates.value = false;
            if (businessClient.value.couponSystem != null) {
                businessClient.value.couponSystem = Number(businessClient.value.couponSystem);
            }
            if (businessClient.value.cityId == '') {
                businessClient.value.cityId = null;
            }
            const saveBusinessClientPromise = api.saveBusinessClient(businessClient.value);
            await Q.delay(400);
            const saveBusinessClientResponse = await saveBusinessClientPromise;
            if (saveBusinessClientResponse.errorMessage) {
                swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: saveBusinessClientResponse.errorMessage });
                if (saveBusinessClientResponse.data && saveBusinessClientResponse.data.clientContactErrors) {
                    clientContactError.value = saveBusinessClientResponse.data.clientContactErrors;
                    contactsHasDuplicates.value = true;
                }
                return;
            }
            if (saveBusinessClientResponse.data) {
                let errorAlertWas = false;
                if (saveBusinessClientResponse.data.errorsClientDetails && saveBusinessClientResponse.data.errorsClientDetails.length) {
                    errorsClientDetails.value = saveBusinessClientResponse.data.errorsClientDetails;
                    let errorMsg = '';
                    if (saveBusinessClientResponse.data.errorsClientDetails.includes('email')) {
                        errorMsg = t('businessClient.alert.incorrect-email');
                    } else if (saveBusinessClientResponse.data.errorsClientDetails.includes('phone1')) {
                        errorMsg = t('businessClient.alert.incorrect-phone1');
                    } else if (saveBusinessClientResponse.data.errorsClientDetails.includes('phone2')) {
                        errorMsg = t('businessClient.alert.incorrect-phone2');
                    }
                    swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: errorMsg });
                    errorAlertWas = true;
                }
                if (saveBusinessClientResponse.data.clientContactErrors && saveBusinessClientResponse.data.clientContactErrors.length) {
                    clientContactError.value = saveBusinessClientResponse.data.clientContactErrors;
                    if (!errorAlertWas) {
                        swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: t('businessClient.alert.incorrect-contact-data') });
                    }
                    errorAlertWas = true;
                }
                if (errorAlertWas) {
                    return;
                }
                clientContactError.value = [];
                errorsClientDetails.value = [];
                await swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: t('alert.business-client.updated'),
                    showConfirmButton: false,
                    timer: 1500
                });
                router.push({ name: 'businessClientView', params: { id: props.id } });
            }
        }
        const submitForm = async (event: any) => {
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                swal.fire({
                    icon: 'warning',
                    title: t('alert.mandatoryfields'),
                    confirmButtonText: t('button.close')
                });
            } else {
                updateBusinessClient();
            }
        };

        function setActiveBtn(activeBtnValue: number) {
            router.push({ name: 'businessClientEdit', params: { id: props.id, page: activeBtnValue } });
        }

        return {
            businessClient,
            back,
            loaded,
            submitForm,
            clientContactError,
            errorsClientDetails,
            contactsHasDuplicates,
            setActiveBtn,
            activeBtn,
            showFooterActions,
            amountOrder,
            totalInvoices,
            totalAllReceipts,
            totalBindedReceipts,
            totaInvoiceslLeftToBind,
            balance,
            currencySign
        };
    }
});
